import React from 'react';
import "./Menu.css";
import ScrollAnimation from 'react-animate-on-scroll';

import menu1 from '../images/la_carte_1.png';
import menu2 from '../images/la_carte_2.png';



const Menu = () => {
    return (

        <>


            <div className="menu-container">

                <ScrollAnimation animateIn="fadeIn">

                    <h1 className="menu-title"> LA CARTE </h1>
                </ScrollAnimation>

                <div className="menu-menu-container">

                    <img src={menu1} alt="" className="menu" loading="lazy" />
                    <img src={menu2} alt="" className="menu" loading="lazy"/>
                </div>


            </div>

        </>
    )
}

export default Menu