import React from 'react';
import ReactPlayer from 'react-player'
import './Film.css';
import ScrollAnimation from 'react-animate-on-scroll';

const Film = () => {

    return (
        <div className="film-container">

            <ScrollAnimation animateIn="fadeIn">
                <h1 className="menu-title title-philosophie"> PHILOSOPHIE </h1>
            </ScrollAnimation>

            <div className='video1'>
                <ReactPlayer
                  
                    url="https://vimeo.com/748344963?dnt=1"
                    controls={true}
                    width="1000px"
                    height="500px"
                    loading="lazy"                   
                />
            </div>

        </div>
    )

}

export default Film