import React from 'react';
import './Header.css';
import img1 from '../images/main-img1.jpg';
import img2 from '../images/main-img2.jpg';
import img3 from '../images/main-img3.jpg';
import img4 from '../images/Header-img-1.png';
import img5 from '../images/header-image-2.png';
import img6 from '../images/header-image-3.png';
import img7 from '../images/header-image-4.png'

const Header = () => {
    let images = [img1, img2, img3, img4, img5, img6, img7]
    let randomImgIndex = Math.floor(Math.random() * images.length)
    let randomImage = images[randomImgIndex]
    return (
        <>
            <div className="header-containter"> 
            <img className="main-img" src={randomImage} alt="" />
            <h1 className="main-title">LE BAR TABAC</h1>
            
            </div>
        </>
    )
}

export default Header;