import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import './Story.css';
import images from '../images';

const Story = () => {

   
    return (
        <div className="stories-container">
            <ScrollAnimation animateIn="fadeIn">
                <h1 className="menu-title"> L’HISTOIRE </h1>
            </ScrollAnimation>

            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1970</h1>
                    <p>It all started with a <br /> drink.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[63]} alt="" className="story-img img15" loading="lazy"/>
                       
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1970</h1>
                    <p>Le Bar Tabac founder Laurent D'Olivaud with friends.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[62]} alt="" className="story-img img15" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1970</h1>
                    <p>Laurent spent every summer at the Atlantic coast. He still does.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[60]} alt="" className="story-img img15" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1970</h1>
                    <p>Laurent D'Olivaud's grandparents ran a BAR TABAC in Paris.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[58]} alt="" className="story-img img" loading="lazy"/>
                        <img src={images[59]} alt="" className="story-img img" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-1">
                <div className="story-text">
                    <h1 className="mobile-padding">1970</h1>
                    <p className="mobile-padding-p">This is Charles Gerard D'Olivaud with friends.  He is Laurent's grandfather  and inspired him to open LE BAR TABAC.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[57]} alt="" className="story-img img15" loading="lazy" />
                    </div>
                </ScrollAnimation>
            </div>


            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1954</h1>
                    <p>It is here that two famous directors first met.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[56]} alt="" className="story-img img15" loading="lazy" />
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container">
                <div className="story-text ">
                    <h1>1965</h1>
                    <p>Famous guests in LE BAR TABAC, Boulevard Voltaire.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[52]} alt="" className="story-img "  loading="lazy"/>
                        <img src={images[53]} alt="" className="story-img "  loading="lazy"/>
                    </div>
                    <div className="story-images">
                        <img src={images[54]} alt="" className="story-img "  loading="lazy"/>
                        <img src={images[55]} alt="" className="story-img "  loading="lazy"/>
                    </div>
                </ScrollAnimation>

            </div>

            <div className="story-container">
                <div className="story-text ">
                    <h1>1961</h1>
                    <p>Some famous movies have been shot in  LE BAR TABAC.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[49]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[50]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[51]} alt="" className="story-img img3" loading="lazy" />
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container">
                <div className="story-text ">
                    <h1>1959</h1>
                    <p>Le Bar Tabac in the movie <br /> "Pickpockets".</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[45]} alt="" className="story-img " loading="lazy"/>
                        <img src={images[46]} alt="" className="story-img " loading="lazy"/>
                    </div>
                    <div className="story-images">
                        <img src={images[47]} alt="" className="story-img " loading="lazy"/>
                        <img src={images[48]} alt="" className="story-img " loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container">
                <div className="story-text ">
                    <h1>1960</h1>
                    <p>Another movie that has partly been shot at LE BAR TABAC: Tirez sur le pianiste.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[39]} alt="" className="story-img" loading="lazy"/>
                        <img src={images[40]} alt="" className="story-img" loading="lazy"/>
                    </div>
                    <div className="story-images">
                        <img src={images[41]} alt="" className="story-img" loading="lazy"/>
                        <img src={images[42]} alt="" className="story-img" loading="lazy"/>
                    </div>
                    <div className="story-images">
                        <img src={images[43]} alt="" className="story-img" loading="lazy"/>
                        <img src={images[44]} alt="" className="story-img" loading="lazy"/>

                    </div>
                    <div className="text-after-images">
                        <p> "Its a documentary about shyness"</p>
                        <p> (Francois Truffaut, 1959)</p>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container story-2">

                <div className="story-text">
                    <h1>1973</h1>
                    <p>Francois Truffaut and Jacqueline Bisset at LE BAR TABAC. Today would have FT's 90th birthday.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[36]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[37]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container">
                <div className="story-text ">
                    <h1>1960</h1>
                    <p>A bout de souffle at LE BAR TABAC, Paris.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[33]} alt="" className="story-img" loading="lazy"/>
                        <img src={images[34]} alt="" className="story-img" loading="lazy"/>
                    </div>
                    <div className="story-images">
                        <img src={images[35]} alt="" className="story-img" loading="lazy"/>
                        <img src={images[32]} alt="" className="story-img" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container">
                <div className="story-text ">
                    <h1>1964</h1>
                    <p> Dancing at <br /> LE BAR TABAC.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[30]} alt="" className="story-img"  loading="lazy"/>
                        <img src={images[31]} alt="" className="story-img"  loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container">
                <div className="story-text ">
                    <h1 >1978</h1>
                    <p>This is Laurent D'Olivaud at 20. 4 years before he opened the BAR TABAC in East Berlin.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[29]} alt="" className="story-img img29" loading="lazy" />
                        <img src={images[29]} alt="" className="story-img img29" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container">
                <div className="story-text ">
                    <h1>1979</h1>
                    <p>Laurent became <br /> a photographer.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[28]} alt="" className="story-img img29" loading="lazy"/>
                        <img src={images[28]} alt="" className="story-img img29" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container">
                <div className="story-text ">
                    <h1>1979</h1>
                    <p>at first he was obsessed with photographing women.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[27]} alt="" className="story-img img15" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container">
                <div className="story-text ">
                    <h1 className="mobile-padding">1981</h1>
                    <p className="mobile-padding-p"> For a short time Laurent worked as a fireman in Paris. That did not stop him from taking photos everyday.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[24]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[25]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[26]} alt="" className="story-img img3" loading="lazy"/>
                    </div>
                </ScrollAnimation>

            </div>
            <div className="story-container story-2">

                <div className="story-text ">
                    <h1 className="mobile-padding">1981</h1>
                    <p className="mobile-padding-p">Laurent travelled to Germany not knowing he would find the love of his life and spend the whole life in East Berlin.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[23]} alt="" className="story-img img15" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-2">
                <div className="story-text">
                    <h1>1981</h1>
                    <p>Laurent immediately became infatuated with  Berlin.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[22]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[22]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1981</h1>
                    <p>Love was in the air that spring - even and especially behind the iron curtain.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">

                    <div className="story-images">
                        <img src={images[18]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[19]} alt="" className="story-img img4" loading="lazy"/>
                    </div>

                    <div className="story-images">
                        <img src={images[20]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[21]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-2">

                <div className="story-text">
                    <h1>1981</h1>
                    <p>that spring the young frenchman met a girl from East Berlin: Paula. Of course.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[17]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[17]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-2">

                <div className="story-text story-text-2">
                    <h1>1981</h1>
                    <p>Paula aspired to be a dancer. She worked in a factory.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[15]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[16]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1981</h1>
                    <p>It's the 20th of May when Paula and Laurent kiss for the first time.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[14]} alt="" className="story-img img15" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-1">
                <div className="story-text">
                    <h1 className="mobile-padding">1981</h1>
                    <p className="mobile-padding-p">Laurent and Paula took an apartment in the basement at the corner of Christinenstr and Schwedterstr.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[10]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[11]} alt="" className="story-img img4" loading="lazy"/>
                    </div>

                    <div className="story-images">
                        <img src={images[12]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[13]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container">
                <div className="story-text ">
                    <h1>1982</h1>
                    <p>It is exactly here where Laurent opened Le Bar Tabac.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[8]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[9]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[38]} alt="" className="story-img img3" loading="lazy"/>
                    </div>
                </ScrollAnimation>

            </div>


            <div className="story-container">
                <div className="story-text ">
                    <h1>1982</h1>
                    <p>LE BAR TABAC, Berlin, 4 weeks before its opening on the 3rd of Septembre.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                       
                        <img src={images[5]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[6]} alt="" className="story-img img3" loading="lazy"/>
                        <img src={images[7]} alt="" className="story-img img3" loading="lazy"/>
                    </div>
                </ScrollAnimation>

            </div>

            <div className="story-container story-2">

                <div className="story-text">
                    <h1 className="mobile-padding">1982</h1>
                    <p className="mobile-padding-p">On the 3rd of September LE BAR TABAC celebrated its opening as the first in-official french Bar in East-Berlin.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">

                        <img src={images[2]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[3]} alt="" className="story-img img4" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>

            <div className="story-container story-1">
                <div className="story-text">
                    <h1>1982</h1>
                    <p>LE BAR TABAC had no license but was nonetheless tolerated by the authorities.</p>
                </div>
                <ScrollAnimation animateIn="fadeIn">
                    <div className="story-images">
                        <img src={images[0]} alt="" className="story-img img1" loading="lazy"/>
                        <img src={images[1]} alt="" className="story-img img1" loading="lazy"/>
                    </div>
                </ScrollAnimation>
            </div>












































        </div>
    )
}

export default Story