import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { useState} from 'react'
import hamburger from '../images/hamburger_2.png'
import cancel from '../images/x_2.png'
import insta from '../images/insta.png';

const Navbar = () => {

const [nav, setNav ] = useState(false)

const handleClick = () => setNav(!nav)

    return (
        <nav className="navbar">
            <div className="logo">
                LE BAR TABAC
            </div>

            <ul className="desktop-navlinks">
                <li>
                    <Link style={{textDecoration: "none"}}  to="/">MAISON</Link>
                </li>
                <li>
                    <Link style={{textDecoration: "none"}} to="/story"> L'HISTOIRE</Link>
                </li>
                <li>
                    <Link style={{textDecoration: "none"}} to="/menu">LA CARTE</Link>
                </li>
                <li>
                    <Link style={{textDecoration: "none"}} to="/film">PHILOSOPHIE</Link>
                </li>
                <li>
                    <Link style={{textDecoration: "none"}} to="/contact">CONTACT</Link>
                </li>
                <div className="insta-logo">
                    <a href="https://www.instagram.com/le_bar_tabac/" target="_blank" rel="noopener noreferrer">
                        <img src={insta} alt="" width="25" />
                    </a>
                </div>
            </ul>

            <div onClick={handleClick} className="hamburger">
                {!nav ? <img className='hamburger-icon' src={hamburger} alt=""/> : <img className='hamburger-icon' src={cancel} alt=""/>}
            </div>

           {nav &&
            <ul className="mobile-navlinks">
                <li>
                    <Link onClick={handleClick} style={{textDecoration: "none"}}  to="/">MAISON</Link>
                </li>
                <li>
                    <Link onClick={handleClick} style={{textDecoration: "none"}} to="/story"> L'HISTOIRE</Link>
                </li>
                <li>
                    <Link onClick={handleClick} style={{textDecoration: "none"}} to="/menu">LA CARTE</Link>
                </li>
                <li>
                    <Link onClick={handleClick} style={{textDecoration: "none"}} to="/film">PHILOSOPHIE</Link>
                </li>
                <li>
                    <Link onClick={handleClick} style={{textDecoration: "none"}} to="/contact">CONTACT</Link>
                </li>
               
            </ul>
           } 


        </nav>
    )
    }

export default Navbar