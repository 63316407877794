import img1 from './images/1982NoLicence1.png';
import img2 from './images/1982NoLicence2.jpg';
import img3 from './images/firstFrenchBar.png';
import img4 from './images/1982barTabacOpening.jpg';
import img5 from './images/4weeksBeforeOpening.png';
import img6 from './images/4weeksBeforeOpening2.webp';
import img7 from './images/4weeksBeforeOpening3.webp';
import img8 from './images/4weeksBeforeOpening4.webp';
import img9 from './images/1982_where_Laurent_opend_bar.jpg'
import img10 from './images/1982_where_Laurent_opened_bar_2.jpg';
import img11 from './images/1981apartmentInBasement4.jpg';
import img12 from './images/1981apartmentInBasement3.jpg';
import img13 from './images/1981apartmentInBasement2.jpg';
import img14 from './images/1981apartmentInBasement.jpg';
import img15 from './images/paula&LaurentKiss.png';
import img16 from './images/PaulaFactory.png';
import img17 from './images/lauraDancer.png';
import img18 from './images/1981frenchmentMetAGirl.png';
import img19 from './images/1981LoveInTheAir.png';
import img20 from './images/1981LoveInTheAir(2).jpg';
import img21 from './images/1981LoveInTheAir(3).jpg';
import img22 from './images/1981LoveInTheAir(4).jpg';
import img23 from './images/LaurenInfatuated.png';
import img24 from './images/1981LaurentTraveledToGermany.png';
import img25 from './images/photographer.jpg';
import img26 from './images/photographer2.jpg';
import img27 from './images/1981LaurentWorkedAsFireman.png';
import img28 from './images/1979obsessedWithPhotography.png';
import img29 from './images/1979LaurentBecamePhotographer.png';
import img30 from './images/1978Laurent20yearsOld.png';
import img31 from './images/1964DancingAtBarTabac(2).jpg';
import img32 from './images/1964DancingAtBarTabac.png';
import img33 from './images/1960barTabacParis.png';
import img34 from './images/1960souffle(2).jpg';
import img35 from './images/1960souffle(3).jpg';
import img36 from './images/1960souffle.jpg';
import img37 from './images/1973Truffaut&Bisset.jpg';
import img38 from './images/FT.jpg'
import img39 from './images/exactlyWhereLaurentOpenedLeBar.png';
import img40 from './images/pianist-movie-3.jpg';
import img41 from './images/pianist_movie-2.jpg';
import img42 from './images/pianist_movie.jpg';
import img43 from './images/1960PianistMovie(3).jpg';
import img44 from './images/1960PianistMovie(2).jpg';
import img45 from './images/1960PianistMovie.jpg';
import img46 from './images/1959Pickpockets.jpg';
import img47 from './images/pickpockets2.jpg';
import img48 from './images/pickpockets3.jpg';
import img49 from './images/pickpockets4.jpg';
import img50 from './images/famousMovies1961(2).jpg';
import img51 from './images/famousMovies1961(3).jpg';
import img52 from './images/famousMovies1961.jpg';
import img53 from './images/barTabacFamousPpl(2).jpg';
import img54 from './images/1965famousGuests.png';
import img55 from './images/barTabacFamousPpl(3).jpg';
import img56 from './images/barTabacFamousPpl.jpg';
import img57 from './images/1954twoFamousDirectors.png';
import img58 from './images/1970laurentsGrandad.png';
import img59 from './images/grandparents_ran_bar.jpg';
import img60 from './images/grandparents_ran_bar-2.jpg';
import img61 from './images/1970everySummerAtAtlantik.png';
import img62 from './images/1970lovedSwimming.png';
import img63 from './images/1970LaurentWithFriends.png';
import img64 from './images/1970startedWithADrink.png';

export default [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64
    

]