import React from 'react';
import './Contact.css';
import ScrollAnimation from 'react-animate-on-scroll';
import contactImg from '../images/contact.jpeg'

const Contact2 = () => {
    return (
        <div className="background-container-contact">
            <ScrollAnimation animateIn="fadeIn">
                <h1 className="menu-title"> CONTACT </h1>
            </ScrollAnimation>

            <div className="email-img">
                <img src={contactImg} alt="email" loading="lazy"/>
            </div>


            <div className="bar-info-container">

                <div>

                    <p>Christinenstr. 24</p>
                    <p>10119 Berlin</p>
                </div>
                <div>
                    <p>Öffnungszeiten: </p>
                    <p> Mon-Son ab 19 Uhr</p>
                </div>

                <div>

                    <p>instagram: le_bar_tabac </p>
                </div>
            </div>
        </div>
    )
}

export default Contact2