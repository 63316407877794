import React from "react";
import Header from "./components/Header";
import Film from "./components/Film";
import Story from "./components/Story";
import Menu from "./components/Menu";
import { Routes, Route } from 'react-router-dom';
import Contact from "./components/Contact";
import './App.css';
import Navbar from "./components/Navbar";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/story" element={<Story />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/film" element={<Film />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

    </>
  );
}

export default App;
